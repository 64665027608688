import React from "react";

import { AppContextProvider } from "../context";
import { useAppInitialization } from "../hooks";

import "../styles/global.css";
import "../styles/flex.css";
import "../styles/margin.css";
import "../styles/padding.css";
import "../styles/width.css";
import "../styles/text.css";

const RequestSentConfirmation = () => {
  useAppInitialization();

  return (
    <div className="w-50 secondary-page-body flex flex-column flex-align-center">
      <img
        className="m-t-6 m-b-6"
        src="/img/logo.png"
        width="400"
        alt="Selfcity"
      />
      <div className="bg-green-slfc p-l-4 p-r-4 p-t-4 p-b-4 flex flex-column flex-align-center">
        <img src="/img/check_icon.png" alt="" />
        <h1 className="center">
          Votre demande a bien été prise en compte&#8239;!
        </h1>
        <p className="center text-2xl">
          Notre équipe support reviendra vers vous très rapidement pour
          planifier votre RDV.
        </p>
      </div>
    </div>
  );
};

export default () => {
  return (
    <AppContextProvider>
      <RequestSentConfirmation />
    </AppContextProvider>
  );
};
